/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'
import Colors from '../../utils/Colors'

const StyledInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  border: 2px solid;
  border-color: ${(props) => (props.theme === 'white' ? Colors.lilacBackground : Colors.darkM_Secondary)};
  padding: 0.8rem 1rem;
  border-radius: 7px;
  margin-bottom: 0.5rem;
  transition: 0.3s;
  background-color: ${(props) => (props.theme === 'white' ? 'white' : Colors.darkM_Input_Background)};
  color: ${(props) => (props.theme === 'white' ? 'black' : 'white')};
  ${({ error }) =>
    error &&
    `
    border-color: #ff4136;
    background-color: ${(props) => (props.theme === 'white' ? 'white' : Colors.darkM_Input_Background)};
	`}

  &::placeholder {
    color: #a7a7a7;
  }
`

function Input(props) {
  return <StyledInput {...props} />
}

export default Input
