import React from 'react'
import * as t from 'utils/Typography'
import Colors from 'utils/Colors'
import styled from 'styled-components'
import { media } from 'utils/MediaQueries'
import ContactForm from 'components/Form/Form'

const WorkWithMeContainer = styled.div`
  --box-shadow-color: ${(props) => (props.theme === 'white' ? Colors.shadow : Colors.darkM_Shadow)};
  padding: 80px;
  width: 73%;
  border-radius: 6px;
  box-shadow: 0 2px 26px 0 var(--box-shadow-color);
  background-color: ${(props) => (props.theme === 'white' ? Colors.white : Colors.darkM_Background)};
  text-align: center;
  position: relative;
  margin: 100px auto -150px auto;
  ${t.LargeP} {
    max-width: 80%;
    margin: 0 auto 28px auto;
    color: ${(props) => (props.theme === 'white' ? Colors.darkest : Colors.darkM_Text)};
  }
  ${t.H1} {
    color: ${(props) => (props.theme === 'white' ? Colors.darkest : Colors.darkM_Text)};
  }
  ${media.tablet`
    width: auto;
    padding: 40px;
    margin: 50px 30px -100px 30px;
  `};
`

function WorkWithMe(props) {
  const { theme } = props

  return (
    <WorkWithMeContainer theme={theme}>
      <t.H1>
        Say hi!
        <span role="img" aria-label="greet">
          👋🏼
        </span>
      </t.H1>
      <t.LargeP>You have a question or just want to say hi? Contact me! </t.LargeP>
      <ContactForm theme={theme} />
    </WorkWithMeContainer>
  )
}

export default WorkWithMe
