import { getImage } from 'gatsby-plugin-image'
import React from 'react'

import Link from '../link'
import {
  Description,
  ImgWrapper,
  Place,
  PlaceTimeContainer,
  TextContainer,
  Time,
  TimelineElement,
  TimelineImage,
  TimelineWrapper,
  Title,
} from './styles'

function Timeline(props) {
  const { dataSet, theme, images, highlightFirstElm } = props
  return (
    <TimelineWrapper>
      {dataSet.map((element, index) => (
        <TimelineElement key={element.key}>
          <PlaceTimeContainer theme={theme}>
            <div>
              <Place>{element.place}</Place>
              <Place>{element.secondPlace}</Place>
            </div>
            <div>
              <Time>
                <span>{element.startDate}</span>
                <span>{element.endDate}</span>
              </Time>
              {element.duration && <Time style={{ fontStyle: 'italic' }}>{`(${element.duration})`}</Time>}
            </div>
          </PlaceTimeContainer>
          <ImgWrapper theme={theme} firstElement={index === 0 && highlightFirstElm}>
            <TimelineImage
              image={getImage(images[element.imgSrc])}
              alt={element.key}
              imgStyle={{ borderRadius: '50%' }}
            />
          </ImgWrapper>
          <TextContainer>
            <Title theme={theme}>
              {element.jobTitle}
              <Link theme={theme} href={element.companyUrl} target="_blank" rel="noreferrer">
                {element.company}
              </Link>
            </Title>
            <Description dangerouslySetInnerHTML={{ __html: element.description }} />
          </TextContainer>
        </TimelineElement>
      ))}
    </TimelineWrapper>
  )
}

export default Timeline
