const posts = [
  {
    key: '06_post_secure_rn',
    title: 'Secure your React Native Apps before production!',
    link: 'https://manuelrdsg.com/2019/06/secure-your-react-native-apps-before-production/',
    image:
      'https://res.cloudinary.com/manuelrdsg/image/upload/c_scale,h_350,q_auto/v1561893408/Secure%20RN%20Apps/636138-636529868642026891-16x9.jpg',
    readTime: '6 min',
    date: 'Sun, 30 Jun 2019 00:00:00 +0000',
    description:
      'We all know that the weeks before launching an app into production are stressful enough, but do not forget about the vulnerabilities while you finish all the features for the MVP product. Stressed monkey before production In this post I&rsquo;ll talk about three common vulnerabilities when it comes to React Native Apps. Code Obfuscation, disabling logs in production and what happens with the API keys of our app, how can we store them safety?',
  },
  {
    key: '05_post_macos_look',
    title: 'Improving the look of your MacOS Terminal',
    link: 'https://manuelrdsg.com/2018/10/improving-the-look-of-your-macos-terminal/',
    image:
      'https://res.cloudinary.com/manuelrdsg/image/upload/c_scale,h_350,q_auto/v1540145661/Improving%20MacOS%20Terminal/screen-mock-1.jpg',
    readTime: '6 min',
    date: 'Fri, 12 Oct 2018 00:00:00 +0000',
    description:
      "Since I upgraded my Mac to the latest Mojave build, due to it's Dark Mode I was wanting to change the look of my MacOS Terminal. So, after searching and going through a few tutorials I have completely changed the look of my terminal, here you will find a tutorial about which steps have I made to change my terminal.",
  },
  {
    key: '04_post_herodemo',
    title: 'HeroDemo a GLSL Shaders project',
    link: 'https://manuelrdsg.com/2018/04/herodemo-a-glsl-shaders-project/',
    image: 'https://res.cloudinary.com/manuelrdsg/image/upload/c_scale,h_350,q_auto/v1524149281/HeroDemo.jpg',
    readTime: '4 min',
    date: 'Mon, 23 Apr 2018 00:00:00 +0000',
    description:
      'Through the last year I have been learning OpenGL and Shader programming with GLSL. I started learning the basics of OpenGL, how to render objects, how to place them in the scene; and also the basics of shader programming, how the pipeline works and how to render basic effects like directional or ambient lightning. After months learning OpenGL and GLSL I have developed this example project called Hero Demo it is a night scene, with particles effect, SkyBoxing, per-fragment point lightning, water rendering',
  },
]

export default posts
