const workExperience = [
  {
    key: '05_exp_igz_upturn',
    place: 'Madrid, Spain',
    secondPlace: 'New Jersey, USA',
    startDate: 'April 21 - ',
    endDate: 'Present',
    duration: '2 years',
    jobTitle: 'Mobile Lead - ',
    company: 'Bolster',
    description:
      'In 2021, I joined Bolster, working alongside former colleagues from Upturn as well as new team members. As the mobile lead, I took on the responsibility of starting the project from scratch and defining the mobile architecture. ' +
      "During this time I've been <i>learning about:</i> <strong>Lead role, Product development, Python, Hexagonal architecture, AWS</strong>",
    imgSrc: 'exp_bolster',
    companyUrl: 'https://bolster.co/',
  },
  {
    key: '04_exp_igz_upturn',
    place: 'Madrid, Spain',
    secondPlace: 'San Francisco, USA',
    startDate: 'Nov 18 - ',
    endDate: 'April 21',
    duration: '3 years',
    jobTitle: 'Cross-Platform Mobile Engineer - ',
    company: 'Intelygenz / Upturn',
    description:
      'I had the chance to work on a React Native project for Upturn Credit, a well-known American FinTech company. As part of the project, I was responsible for developing their mobile application from scratch. ' +
      '<i>Learned about:</i> <strong>React Native, Jest, Cucumber, React, ATDD, NodeJS, Snapshot Testing, Redux, Architecture</strong>',
    imgSrc: 'exp_upturn',
    companyUrl: 'https://www.upturncredit.com',
  },
  {
    key: '03_exp_igz_telepi',
    place: 'Madrid, Spain',
    startDate: 'Feb 18 - ',
    endDate: 'Nov 18',
    duration: '1 year',
    jobTitle: 'Full Stack Developer - ',
    company: 'Intelygenz',
    description:
      'I worked on an Ionic project for a fast food company in Spain, helping' +
      " to develop their mobile application. As part of Intelygenz's cross-platform" +
      ' development team, I also worked on several other mobile and web projects,' +
      ' mostly using Ionic and React.' +
      '<i>Learned about:</i> <strong>Ionic, ' +
      'Jira, Testing, HTML, CSS</strong>',
    imgSrc: 'exp_igz',
    companyUrl: 'https://www.intelygenz.com',
  },
  {
    key: '02_exp_friking',
    place: 'Cádiz, Spain',
    startDate: 'Jul 17 - ',
    endDate: 'Aug 17',
    duration: '2 months',
    jobTitle: 'Web Developer - ',
    company: 'Friking Design S.L.',
    description:
      'I was part of a team that developed a BackOffice WebApp for Friking, where I contributed to database design and management.' +
      'The WebApp was built using Django in Python' +
      '<i>Learned about:</i> <strong>Django Python, Database Design</strong>',
    imgSrc: 'exp_friking',
    companyUrl: 'https://www.friking.es/',
  },
  {
    key: '01_exp_univ_cadiz',
    place: 'Cádiz, Spain',
    startDate: 'Mar 17 - ',
    endDate: 'Jun 17',
    duration: '5 months',
    jobTitle: 'Computer Science Department Intern - ',
    company: 'University of Cádiz',
    description:
      "Support and evolution of the Android application <i>'GuessIt!'</i> for learning " +
      'languages in a university environment. Database administration and ' +
      'obtaining usage statistics of the application from the MySQL database. ' +
      '<i>Learned about:</i> <strong>Android, MySQL, Database and system administration</strong>',
    imgSrc: 'exp_cs_department',
    companyUrl: 'https://www.uca.es/?lang=en/',
  },
]

export default workExperience
