import React from 'react'

import { PostElement, PostImage, PostReadTime, PostTitle } from './styles'

function BlogPostCard(props) {
  const { post, theme } = props

  return (
    <PostElement theme={theme} href={post.link} key={post.key}>
      {post.image && <PostImage style={{ backgroundImage: `url(${post.image})` }} />}
      <PostTitle theme={theme}>{`${post.title}`}</PostTitle>
      <PostReadTime theme={theme}>{`☕ ${post.readTime} read`}</PostReadTime>
      <p>{post.description}</p>
    </PostElement>
  )
}

export default BlogPostCard
