import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'

import { ProjectCaption, ProjectElement, ProjectImage, ProjectLink, ProjectOverlay, ProjectTitle } from './styles'

function ProjectCard(props) {
  const { project, images, theme } = props

  return (
    <ProjectElement theme={theme} key={project.key}>
      <ProjectImage image={getImage(images[project.imgSrc])} alt="Name Surname" imgStyle={{ borderRadius: 12 }} />
      <ProjectCaption>
        <ProjectTitle>{project.description}</ProjectTitle>
        <ProjectLink href={project.url}>
          <span style={{ marginRight: 5 }}>Take a look!</span>
          <span>
            <FontAwesomeIcon icon={faAngleRight} size="sm" />
          </span>
        </ProjectLink>
      </ProjectCaption>
      <ProjectOverlay />
    </ProjectElement>
  )
}

export default ProjectCard
