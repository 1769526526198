import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

import BlogPostCard from 'components/BlogPostCard'
import { HireMe } from 'components/Button'
import ContentWrapper from 'components/Content'
import Layout from 'components/Layout'
import ProjectCard from 'components/ProjectCard'
import Timeline from 'components/Timeline'
import WorkWithMe from 'components/workWithMe'

import * as t from 'utils/Typography'

import { education, posts, projects, workExperience } from '../Data'
import { myContext } from '../themeProvider'
import { AboveFold, Background, HomepageWrapper, Table, TableProjects } from '../utils/styles'

function Homepage(props) {
  const { data } = props

  return (
    <myContext.Consumer>
      {(context) => (
        <Background theme={context.theme}>
          <HomepageWrapper theme={context.theme}>
            <Layout theme={context.theme} onChangeTheme={context.changeTheme} bigFooter>
              <AboveFold theme={context.theme}>
                <GatsbyImage
                  image={getImage(data.profile)}
                  alt="Name Surname"
                  className="avatar"
                  imgStyle={{ borderRadius: '50%' }}
                />
                <t.H1 primary align="center">
                  ¡Hola! I&apos;m Manuel Rodriguez-Sanchez
                </t.H1>
                <t.LargeP align="center" max55>
                  {"I'm a Cross-Platform Mobile Engineer, currently freelancing on the side."}
                </t.LargeP>
                <HireMe
                  large
                  onClick={() =>
                    window.scrollTo({ top: document.body.scrollHeight - 900, left: 0, behavior: 'smooth' })
                  }
                  book
                  theme={context.theme}>
                  Say hi!
                  <span role="img" aria-label="greet">
                    👋🏼
                  </span>
                </HireMe>
              </AboveFold>
              <ContentWrapper theme={context.theme}>
                <t.H2 primary align="center" bold>
                  {'About me '}
                  <span role="img" aria-label="Greet">
                    🙋🏻‍♂️
                  </span>
                </t.H2>
                <t.P align="center" max70 className="who-desc" alt={'description'}>
                  I’m based in <strong>Europe</strong>, mainly in Madrid, Spain, but I also enjoy traveling and working
                  remotely from different cities. I studied Informatics Engineering in Cádiz, a small town in the south
                  of Spain, and London. I’m really passionate about React Native, Cross-Platform technologies, and how
                  people interact with devices. I absolutely love collecting and listening to music, and I’m always
                  carrying a film camera with me to snap some pictures.
                </t.P>
                <t.H2 primary align="center" bold className="portfolio">
                  {'What I’ve been up to '}
                  <span role="img" aria-label="Laptop">
                    👨🏻‍💻
                  </span>
                </t.H2>
                <Timeline dataSet={workExperience} theme={context.theme} images={data} highlightFirstElm />
                <t.H2 primary align="center" bold className="portfolio">
                  {'Education '}
                  <span role="img" aria-label="Book">
                    📖
                  </span>
                </t.H2>
                <Timeline dataSet={education} theme={context.theme} images={data} />
                <t.H2 primary align="center" bold className="portfolio">
                  {'Posts '}
                  <span role="img" aria-label="HandWriting">
                    ✍
                  </span>
                </t.H2>
                <Table style={{ alignContent: 'center' }}>
                  {posts.map((post) => (
                    <BlogPostCard post={post} theme={context.theme} />
                  ))}
                </Table>
                <t.H2 primary align="center" bold className="portfolio">
                  {'Projects '}
                  <span role="img" aria-label="Phone">
                    📱
                  </span>
                </t.H2>
                <TableProjects>
                  {projects.map((project) => (
                    <ProjectCard project={project} theme={context.theme} images={data} />
                  ))}
                </TableProjects>
              </ContentWrapper>
              <WorkWithMe theme={context.theme} />
            </Layout>
          </HomepageWrapper>
        </Background>
      )}
    </myContext.Consumer>
  )
}

export default Homepage

export const pageQuery = graphql`
  query {
    profile: file(relativePath: { eq: "profile.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 250, height: 250, quality: 100, placeholder: TRACED_SVG)
      }
    }
    exp_cs_department: file(relativePath: { eq: "exp_cs_department.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 64, height: 64, quality: 100, placeholder: BLURRED)
      }
    }
    exp_igz: file(relativePath: { eq: "exp_igz.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 64, height: 64, quality: 100, placeholder: BLURRED)
      }
    }
    exp_upturn: file(relativePath: { eq: "exp_upturn.png" }) {
      childImageSharp {
        gatsbyImageData(width: 64, height: 64, quality: 100, placeholder: BLURRED)
      }
    }
    exp_friking: file(relativePath: { eq: "exp_friking.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 64, height: 64, quality: 100, placeholder: BLURRED)
      }
    }
    exp_bolster: file(relativePath: { eq: "exp_bolster.png" }) {
      childImageSharp {
        gatsbyImageData(width: 64, height: 64, quality: 100, placeholder: BLURRED)
      }
    }
    stud_k_university: file(relativePath: { eq: "stud_k_university.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 64, height: 64, quality: 100, placeholder: BLURRED)
      }
    }
    stud_c_university: file(relativePath: { eq: "stud_c_university.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 64, height: 64, quality: 100, placeholder: BLURRED)
      }
    }
    proj_netflax: file(relativePath: { eq: "proj_netflax.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 350, height: 350, quality: 100, placeholder: BLURRED)
      }
    }
    proj_cross_study: file(relativePath: { eq: "proj_cross_study.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 350, height: 350, quality: 100, placeholder: BLURRED)
      }
    }
    proj_upturn: file(relativePath: { eq: "proj_upturn.png" }) {
      childImageSharp {
        gatsbyImageData(width: 350, height: 350, quality: 100, placeholder: BLURRED)
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 350, height: 350, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
