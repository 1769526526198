/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'

import Colors from 'utils/Colors'

const StyledButton = styled.button`
  cursor: pointer;
  padding: 0.7rem 2.5rem;
  border: none;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  background: ${(props) => (props.theme === 'white' ? '#0074d9' : Colors.darkM_Secondary)};
  border-radius: 100px;

  &:focus {
    outline: none;
  }

  &:disabled {
    background: gray;
  }

  ${({ secondary }) =>
    secondary &&
    `
		background: ${(props) => (props.theme === 'white' ? '#001F3F' : Colors.darkM_Secondary)};
	`}
`

function FormButton(props) {
  return <StyledButton {...props} />
}

export default FormButton
