const projects = [
  {
    key: '03_proj_upturn',
    description:
      'Mobile application where users can monitor their credit score and initiate disputes regarding items on their credit report.',
    imgSrc: 'proj_upturn',
    url: '/projects/upturn',
  },
  {
    key: '02_proj_cross',
    description: 'Comparative study between most used Cross-Platform frameworks used in the software industry',
    imgSrc: 'proj_cross_study',
    url: '/projects/cross-platform-study',
  },
  {
    key: '01_proj_netflax',
    description:
      'A bot that automatically adds the release dates of your favorite Netflix shows to your Google Calendar.',
    imgSrc: 'proj_netflax',
    url: '/projects/netflax-calendar',
  },
]

export default projects
