import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import Colors from '../../utils/Colors'
import { media } from '../../utils/MediaQueries'

export const ImgWrapper = styled.div`
  border: 3px solid;
  border-color: ${(props) => {
    if (props.firstElement) {
      return props.theme === 'white' ? Colors.lilacBackground : Colors.darkM_Secondary
    }
    return '#767676'
  }};
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  margin-right: 2em;
  margin-left: 2em;
  padding: 2px;
  ${media.phone`
    margin-left: 0em;
    margin-bottom: 0.5rem;
`};
`

export const TimelineWrapper = styled.ul`
  list-style: none;
  position: relative;
  & > li:not(:last-child) ${ImgWrapper}:after {
    @media (max-width: 768px) {
      display: initial;
    }
    content: '';
    display: inherit;
    position: relative;
    height: 100%;
    width: 2px;
    background-color: #c1c1c1;
    bottom: 0;
    margin-top: 5px;
    left: 50%;
    margin-left: -1px;
  }
  ${media.phone`
    padding: 0;
  `};
`

export const TimelineElement = styled.li`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  &:after {
    clear: both;
    content: ' ';
    display: table;
  }
  &:before {
    content: ' ';
    display: table;
  }

  ${media.phone`
    flex-direction: column;
    margin-bottom: 1rem;
  `};
`

export const PlaceTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 16rem;
  ${media.phone`
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    width: 100%;
  `};
  color: ${(props) => (props.theme === 'white' ? 'black' : Colors.darkM_Text)};
`

export const TimelineImage = styled(GatsbyImage)`
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  flex-grow: 1;
`

export const Title = styled.div`
  font-size: 1.17em;
  font-weight: bold;
  color: ${(props) => (props.theme === 'white' ? Colors.darkest : Colors.darkM_Text_Darken)};
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100rem;
  ${media.phone`
    width: auto;
  `};
`

export const Description = styled.p`
  color: #767676;
  text-decoration: none;
`

export const Place = styled.div`
  font-weight: bold;
  text-decoration: none;
  ${media.phone`
    justify-self: flex-start;
    flex-grow: 1;
  `};
`

export const Time = styled.div`
  ${media.phone`
    justify-self: flex-end;
    flex-grow: 1;
  `};
`
