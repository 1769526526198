const education = [
  {
    key: '02_edu_uni_kingston',
    place: 'London, UK',
    startDate: 'Sept 17 - ',
    endDate: 'Sept 18',
    jobTitle: 'Engineering Exchange Student - ',
    company: 'Kingston University',
    description:
      'During this engineering exchange with Kingston University, I was enrolled in ' +
      'modules such as 3D Graphics Programming, High Level Game Development and ' +
      'Project Management. Throughout the year I worked part-time on writing ' +
      'a paper about Cross-Platform technologies. Also, I learned how is <strong>living ' +
      'in a foreign country and about cultural diversity.</strong>',
    imgSrc: 'stud_k_university',
    companyUrl: 'https://www.kingston.ac.uk/',
  },
  {
    key: '01_edu_uni_cadiz',
    place: 'Cádiz, Spain',
    startDate: 'Sept 14 - ',
    endDate: 'Sept 18',
    jobTitle: 'Informatics Engineering, BSc - ',
    company: 'University of Cádiz',
    description:
      'At the University of Cádiz, I learned the basics of programming and engineering. ' +
      'I <strong>specialized in Software Engineering and project management. </strong> ' +
      'I learned languages and technologies such as Java, C++, Python, JavaScript, Angular and MongoDB, among others.',
    imgSrc: 'stud_c_university',
    companyUrl: 'https://www.uca.es/?lang=en/',
  },
]

export default education
