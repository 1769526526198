import { ErrorMessage, FastField, Field, Form, withFormik } from 'formik'
import React from 'react'
import Recaptcha from 'react-google-recaptcha'
import styled from 'styled-components'
import * as Yup from 'yup'

import Input from '../Input'
import Button from './components/FormButton'
import { Center, Error, InputField } from './styles'

const SucessMessage = styled.div`
  color: ${(props) => (props.theme === 'white' ? 'black' : 'white')};
  font-weight: bold;
`

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY

function ContactForm({ setFieldValue, isSubmitting, values, errors, touched, theme }) {
  return (
    <Form
      name="portfolio-dev"
      method="post"
      data-netlify="true"
      data-netlify-recaptcha="true"
      data-netlify-honeypot="bot-field">
      <InputField>
        <Input
          as={Field}
          type="text"
          name="name"
          component="input"
          aria-label="name"
          placeholder="Full name*"
          error={touched.name && errors.name}
          theme={theme}
        />
        <ErrorMessage component={Error} name="name" />
      </InputField>
      <InputField>
        <Input
          as={Field}
          id="email"
          aria-label="email"
          component="input"
          type="email"
          name="email"
          placeholder="Email*"
          error={touched.email && errors.email}
          theme={theme}
        />
        <ErrorMessage component={Error} name="email" />
      </InputField>
      <InputField>
        <Input
          as={Field}
          component="textarea"
          aria-label="message"
          id="message"
          rows="8"
          type="text"
          name="message"
          placeholder="Message*"
          error={touched.message && errors.message}
          theme={theme}
        />
        <ErrorMessage component={Error} name="message" />
      </InputField>
      {values.name && values.email && values.message && (
        <InputField>
          <FastField
            component={Recaptcha}
            sitekey={RECAPTCHA_KEY}
            name="recaptcha"
            onChange={(value) => setFieldValue('recaptcha', value)}
          />
          <ErrorMessage component={Error} name="recaptcha" />
        </InputField>
      )}
      {values.success && (
        <InputField>
          <Center>
            <SucessMessage theme={theme}>
              Your message has been successfully sent, I will get back to you ASAP!
            </SucessMessage>
          </Center>
        </InputField>
      )}
      <Center>
        <Button theme={theme} secondary type="submit" disabled={isSubmitting || values.success}>
          Submit
        </Button>
      </Center>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: (props) => ({
    name: '',
    email: '',
    message: '',
    recaptcha: '',
    success: false,
    theme: props.theme,
  }),
  validationSchema: () =>
    Yup.object().shape({
      name: Yup.string().required('Full name field is required'),
      email: Yup.string().email('Invalid email').required('Email field is required'),
      message: Yup.string().required('Message field is required'),
      recaptcha: Yup.string().required('Robots are not welcome yet!'),
    }),
  handleSubmit: async ({ name, email, message, recaptcha }, { setSubmitting, resetForm, setFieldValue }) => {
    try {
      const encode = (data) =>
        Object.keys(data)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
          .join('&')
      await fetch('/?no-cache=1', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'portfolio-dev',
          name,
          email,
          message,
          'g-recaptcha-response': recaptcha,
        }),
      })
      await setSubmitting(false)
      await setFieldValue('success', true)
      setTimeout(() => resetForm(), 2500)
    } catch (err) {
      setSubmitting(false)
      setFieldValue('success', false)
      alert('Something went wrong, please try again!') // eslint-disable-line
    }
  },
})(ContactForm)
