import styled from 'styled-components'

import Colors from '../../utils/Colors'
import { media } from '../../utils/MediaQueries'

export const PostImage = styled.div`
  opacity: 1;
  display: block;
  width: auto;
  height: 20rem;
  backface-visibility: hidden;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  box-shadow: 0 2px 26px 0 rgba(0, 0, 0, 0.05);
`

export const PostTitle = styled.div`
  color: ${(props) => (props.theme === 'white' ? Colors.lilacBackground : Colors.darkM_Secondary)};
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;
  font-size: 1.2rem;
`

export const PostReadTime = styled.div`
  color: ${(props) => (props.theme === 'white' ? Colors.lilacBackground : Colors.darkM_Secondary)};
  text-decoration: none;
  font-weight: bold;
  font-style: italic;
  margin-top: 0.5rem;
  font-size: 0.8rem;
`

export const PostElement = styled.a`
  --box-shadow-color-accent: ${(props) => (props.theme === 'white' ? 'rgba(57, 55, 55, 0.4)' : Colors.darkM_Shadow)};
  --box-shadow-color: ${(props) => (props.theme === 'white' ? 'rgba(57, 55, 55, 0.08)' : Colors.darkM_Shadow)};
  display: flex;
  flex-direction: column;
  max-width: 23rem;
  justify-content: flex-start;
  text-decoration: none;
  padding: 1rem;
  border-radius: 20px;
  margin-right: 1rem;
  box-shadow: 0 2px 26px 0 var(--box-shadow-color);
  align-self: flex-start;
  transition: all 0.3s linear 0s;
  ${media.phone`
    margin-bottom: 2rem;
    margin-right: 0rem;
  `};
  &:hover {
    box-shadow: 0 2px 26px 0 var(--box-shadow-color-accent);
    transform: scaleX(1.05) scaleY(1.05);
  }
`
