import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import Colors from '../../utils/Colors'
import { media } from '../../utils/MediaQueries'

export const ProjectCaption = styled.div`
  transition: 0.45s ease-in-out;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const ProjectOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: 0.45s ease-in-out;
  transition: 0.45s ease-in-out;
  background-color: #293347;
  border-radius: 12px;
`

export const ProjectImage = styled(GatsbyImage)`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  backface-visibility: hidden;
  object-fit: cover;
`

export const ProjectElement = styled.div`
  --box-shadow-color: ${(props) => (props.theme === 'white' ? 'rgba(57, 55, 55, 0.08)' : Colors.darkM_Shadow)};
  box-shadow: 0 2px 26px 0 var(--box-shadow-color);
  min-width: 20rem;
  min-height: 20rem;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  transition: 0.25s ease-in-out;
  margin-right: 1rem;

  ${media.phone`
    margin-bottom: 2rem;
    margin-right: 0rem;
  `};

  &:hover {
    ${ProjectImage} {
      transform: scale(1);
    }
    ${ProjectCaption} {
      opacity: 1;
    }
    ${ProjectOverlay} {
      opacity: 1;
    }
  }
`

export const ProjectLink = styled.a`
  margin: 0 auto;
  font-size: 18px;
  padding: 0.5em;
  font-weight: 400;
  border: 2px solid #6c63ff;
  border-radius: 20px;
  color: white;
  background-color: transparent;
  transition: 0.25s ease-in-out;
  text-decoration: none;
  &:hover {
    background-color: #6c63ff;
  }
`

export const ProjectTitle = styled.h1`
  color: white;
  margin-bottom: 2.3rem;
  font-weight: 500;
  font-size: 1.3rem !important;
  margin-left: 1rem;
  margin-right: 1rem;
`
